import { Property } from '../../types/property';

/*
 * Returns images 2nd, 3rd & 4th property images if property has >= 4 images
 */

interface Image {
  url: string;
  blurHash: string;
}

const getThumbImages = (property: Property, locator?: string): Image[] | null => {
  if (property.images && property.images.length >= 4 && (property.premiumListing || property.featuredListing)) {
    const mLocator = locator || Object.keys(property.images[0].urls)[0];

    return property.images
      .map((image) => {
        return { url: image.urls[mLocator] || image.url, blurHash: image.blurhash };
      })
      .slice(1, 4);
  }

  return null;
};

export default getThumbImages;
